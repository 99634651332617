.header {
  font-size: 1.2em;
  background-color: #777;
  padding: 5px 0;
  color: #ddd;
  font-weight: 500;
}
.header > div {
  margin: 0 1em;
}
.content-big {
  color: #eee;
  font-weight: 600;
  font-size: 2.2em;
  margin: 1em 0 !important;
}
.content-small {
  color: #eee;
  margin: 5px 0 0 0px;
  font-size: 0.45em !important;
  font-weight: 400 !important;
}
.seconds {
  color: #eee;
  margin: 6px 0 0 0;
  font-size: 1.3em;
  font-weight: 600;
}
.edit-btn {
  color: #aeca36;
  font-weight: 500;
  font-size: 0.9em;
}
.profile-row {
  flex: 3 !important;
  margin-bottom: 0.8em !important;
}
.profile-row > div {
  padding: 4px;
}
.profile-name {
  color: #eee;
  font-size: 1.7em;
  font-weight: 800;
}
.profile-email {
  color: #eee;
}
.history-row {
  border-bottom: 1px solid #666;
  padding: 1em 2em;
  color: #ccc;
}
.name {
  font-size: 1.2em !important;
  font-weight: 500;
  color: #fff;
  padding-bottom: 5px;
}
.topup{
  padding-bottom: 0!important;
}
.left-content {
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: center;
}
.right-content {
  text-align: right;
  font-size: 0.9em;
  margin-left: 0 !important;
}
.amount {
  font-size: 1em !important;
  font-weight: 500 !important;
  padding: 0 !important;
}
.red {
  color: #ff7171;
}
.green {
  color: #aeca36;
}
.duration {
  font-size: 0.95em;
  font-weight: normal;
}
.date-time {
  font-weight: 500;
  font-size: 0.95em;
}
.location,
.plan-status {
  font-size: 0.85em !important;
}
.am-image-picker-item-content {
  border-radius: 50% !important;
}
.profile-header {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.005em;
  margin: 1.5em;
  color: #eee;
}
input:disabled {
  background-color: #333 !important;
}
.edit-picture {
  margin: 0 auto;
  width: 40%;
}
.picture-wrapper{
  height: 100%;
  width: 100%;
}
.picture-wrapper .am-image-picker-list{
  padding: 0;
  margin: 0;
}
.picture-wrapper .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove{
  display: none;
}
.profile-form input{
  color: #aeca36 !important;
  font-size: 0.9em !important;
  line-height: 1.25!important;
}